import { Menu, styled } from '@mui/material';
import { getMenuPadding } from './utils';

const generalStyles = ({ theme, isWideDropdown }) => ({
  '& .MuiListItemText-root ': {
    height: theme.spacing(3),
    boxSizing: 'border-box',
  },
  '& .MuiDivider-root': {
    borderColor: theme.palette.grey.divider_grey_v1,
    display: isWideDropdown ? 'none' : '',
  },
  '& .MuiMenuItem-root': {
    height: theme.spacing(5.25),

    ':not(:last-child) {': {
      marginBottom: theme.spacing(0.5),
    },
  },
});

export const StyledDropDownMenu = styled(Menu, {
  shouldForwardProp: (prop) => !['isHeaderHidden', 'isWideDropdown'].includes(prop),
})(({ isWideDropdown, isHeaderHidden, theme }) => ({
    '&& .MuiPaper-root': {
      width: isWideDropdown ? '300px' : '224px',
      borderRadius: '6px',
      padding: getMenuPadding(theme, isHeaderHidden, isWideDropdown),
      paddingInlineStart: theme.spacing(1),
      boxShadow: `0px 2px 8px 0px ${theme.palette.background.boxShadow}`,

      '& .MuiList-root': {
        paddingBottom: 0,
        paddingTop: 0,
      },
    },
    '.SearchField': {
      width: isWideDropdown ? '100%' : null,
      marginBottom: isWideDropdown ? '8px' : null,
    },
    '& .dropdown-header:has(.action-wrapper)': {
      '& .SearchField': {
        marginBottom: '0',
      },
    },
    ...generalStyles({ theme, isWideDropdown }),
}));

export const StyledDropDownDivMenu = styled('div', {
  shouldForwardProp: (prop) => prop !== 'withTitle' && prop !== 'isWideDropdown',
})(({ theme, withTitle, isWideDropdown }) => ({
  borderRadius: '10px',
  width: isWideDropdown ? '300px' : '224px',
  padding: withTitle !== null && withTitle !== undefined ? theme.spacing(1.5, 0, 1, 0) : theme.spacing(0, 0, 0.2, 0),
  paddingInlineStart: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  boxShadow: `0px 2px 8px 0px ${theme.palette.background.boxShadow}`,

  ...generalStyles({ theme }),
}));
