import { styled, Divider, Stack, Box } from '@mui/material';
import SearchField from 'common/components/searchField';

export const StyledDropDownTitle = styled('span')(({ theme }) => ({
  display: 'inline-block',
  color: theme.palette.text.txt_default,
  fontSize: '0.875rem',
  fontFamily: theme.fonts.robotoMedium,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  width: '100%',
  lineHeight: '24px',
}));

export const StyledStack = styled(Stack, { shouldForwardProp: (prop) => prop !== 'hasTitle' })(
  ({ theme, hasTitle }) => ({
    '& div:first-of-type': {
      marginTop: !hasTitle && '0',
    },

    '& .sub-title': {
      color: theme.palette.text.secondary_txt,
      fontSize: '0.75rem',
      fontFamily: theme.fonts.robotoRegular,
    },

    padding: '0 8px',
    paddingInlineEnd: '16px'
  }),
);

export const StyledDivider = styled(Divider, { shouldForwardProp: (prop) => prop !== 'shouldClearStackMargin' })(
  ({ theme, shouldClearStackMargin }) => ({
    marginTop: shouldClearStackMargin ? '0 !important' : 'inherit',
    marginBottom: `${theme.spacing(1)} !important`,
  }),
);

export const ClearButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDirty',
})(({ theme, disabled }) => ({
  fontSize: '0.875rem',
  color: disabled ? theme.palette.text.disable_txt : theme.palette.text.secondary_txt,
  display: 'flex',
  lineHeight: '20px',
  alignItems: 'center',
  marginLeft: 'auto',
  '& .MuiSvgIcon-root path': {
    fill: disabled ? theme.palette.text.disable_txt : theme.palette.text.secondary_txt,
  },
  '&:hover': {
    cursor: disabled ? 'not-allowed' : 'pointer',
  },
}));

export const SelectButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDirty',
})(({ theme, disabled }) => ({
  fontSize: '0.875rem',
  lineHeight: '20px',
  color: disabled ? theme.palette.text.disable_txt : theme.palette.generic.sysaid,

  '&:hover': {
    cursor: disabled ? 'not-allowed' : 'pointer',
  },
}));

export const StyledSearchField = styled(SearchField)(() => ({
  marginBottom: '0',
}));

export const ActionWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMultiActions',
})(({ isMultiActions }) => ({
  display: 'flex',
  paddingTop: '8px',
  paddingBottom: '6px',
  justifyContent: isMultiActions ? 'space-between' : 'end',
}));

export const StyledListLabel = styled(Box)(({ theme }) => ({
  color: theme?.palette?.text?.secondary_txt,
  fontSize: '12px',
  marginTop: '0 !important',
  marginBottom: '16px !important',
}));
