import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/store/slice';
import { useAuditLog } from 'features/resolutionPanel/middlePanel/auditLog/hooks';
import useAnalytics from 'common/utils/hooks/useAnalytics';
import { TRACK_EVENTS } from 'constants/trackEvents';
import { SwitchToggle } from '../../controls/Switch/SwitchToggle';

export const ExpandButton = ({ isAllMessagesExpanded = false, handleToggleExpandAllMessages }) => {
    const ticketAuditLogs = useSelector(selectAuditLog);
    const { selectedFilterType, toggleAuditLogsProperty } = useAuditLog();
    const currentAuditLogs = ticketAuditLogs[selectedFilterType];

    const trackEvents = useAnalytics();

    const switchStatusHandler = ({ target: { checked } }) => {
        handleToggleExpandAllMessages(checked);

        const auditLogs = currentAuditLogs?.list.filter((auditLog) => 'isRichTextTruncated' in auditLog);
        const truncatedLogsIds = auditLogs
            .filter(({ isRichTextTruncated }) => isRichTextTruncated === checked)
            .map(({ id }) => id);
        const allLogsIds = auditLogs.map(({ id }) => id);
        const idsToToggle = truncatedLogsIds.length === auditLogs.length ? allLogsIds : truncatedLogsIds;

        trackEvents(checked ? TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_ON : TRACK_EVENTS.EXPAND_ALL_JOURNEY_TOGGLE_OFF, {});
        toggleAuditLogsProperty({ auditLogIds: idsToToggle, property: 'isRichTextTruncated' });
    };

    return (
        <SwitchToggle dataTestId="expand-switch" isChecked={isAllMessagesExpanded} handleSwitchChange={switchStatusHandler} />
    );
};

ExpandButton.propTypes = {
    isAllMessagesExpanded: PropTypes.bool,
    handleToggleExpandAllMessages: PropTypes.func,
}
