import { SelectItem } from '../SelectItem';
import { CONSTANTS } from '../constants';

export const defaultGetItemSize = () => CONSTANTS.DROPDOWN_ITEM_HEIGHT;
export const defaultRowRenderer = ({ ids, index, style, isInFocus }) => (
  <div data-testid='row-container' key={index} style={{ ...style, paddingInlineEnd: '12px' }}>
        <SelectItem   style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }} id={ids[index]} isInFocus={isInFocus} />
  </div>
);
