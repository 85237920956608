import { Button, styled } from '@mui/material';

const StyledDueDateButtonIgnoreProps = ['isError', 'isTemplatePage'];
export const StyledDueDateButton = styled(Button, {
  shouldForwardProp: (prop) => !StyledDueDateButtonIgnoreProps.includes(prop),
})(({ isTemplatePage, isError, theme }) => {
  const mainColor = isError ? theme.palette.labels.bt_red_reg : theme.palette.icons.icons_grey_v1;
  const hoveredColor = isError ? theme.palette.labels.bt_red_hover : theme.palette.text.txt_default;
  const hoveredBackground = isError ? theme.palette.labels.bt_red_light : theme.palette.labels.bt_black_light;
  let padding = '4px';
  let lineHeight = '16px';

  if (isTemplatePage) {
    lineHeight = '22px';
    padding = '6px 15px 6px 8px';
  }

  return {
    borderRadius: '16px',
    border: `1px solid ${mainColor}`,
    color: mainColor,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight,
    padding: '3px 15px',
    width: '100%',
    minHeight: '24px !important',
    maxWidth: '100%',
    textTransform: 'none',
    transition: '0.1s',
    overflow: 'hidden',
    '.text-dueDateField': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    margin: '0 4px'
  },
    '&.MuiButtonBase-root': {
      padding,
      width: 'inherit',
      display: 'flex',
      '.MuiButton-endIcon': {
        marginRight: 0,
        marginLeft: '-8px',
      },
      '& .MuiButton-label': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'inherit',
        display: 'block',
      },
    },
    '&:hover': {
      color: hoveredColor,
      borderColor: hoveredColor,
      background: hoveredBackground,
    },
    '.openPickerIcon *': {
      stroke: mainColor,
    },
    '&:hover *': {
      stroke: hoveredColor,
    },
  };
});
